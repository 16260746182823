import { Component, Input } from '@angular/core';
import { IncluyeItem } from '../../interfaces/interfaces';

@Component({
  selector: 'app-incluye-list',
  templateUrl: './incluye-list.component.html',
  styleUrls: ['./incluye-list.component.scss']
})
export class IncluyeListComponent {

  @Input() listadoServicios: IncluyeItem[] = [];

  constructor() { }
}
