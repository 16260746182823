import { Component, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-swiper-full-wide',
  templateUrl: './swiper-full-wide.component.html',
  styleUrls: ['./swiper-full-wide.component.scss'],
})
export class SwiperFullWideComponent {
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-custom-pagination',
    clickable: true,
    // el: 'asd'
  };

  @Input() mobile = false;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    pagination: true,
    slidesPerView: 1,
    // centeredSlides: true,
    spaceBetween: 0,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
    navigation: {
      nextEl: '.swiper-full-wide-next',
      prevEl: '.swiper-full-wide-prev',
    },
    // effect: 'slide'
  };
  @Input()
  listadoItems: any[] = [];

  constructor() {}
}
