import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PreguntasService } from 'src/app/services/preguntas.service';

@Component({
  selector: 'app-standard-slide',
  templateUrl: './standard-slide.component.html',
  styleUrls: ['./standard-slide.component.scss'],
})
export class StandardSlideComponent implements OnInit {
  @ViewChild('mainSlide') mainSlide: ElementRef;

  menu: boolean = false;
  appViaje: boolean = false;
  appBeneficios: boolean = false;
  
  public smallHeader = false;
  @Input() initModal: boolean = true;
  @Input() smallHeaderAlways = false;
  @Input() pais = false;

  public preguntaTxt = '';
  public fieldsError = '';
  public successMsg = false;
  public errorMsg = false;
  public loading = false;
  public myRecaptcha = false;

  constructor(
    private router: Router,
    private preguntasService: PreguntasService
    ) {
    if (this.router.url.indexOf('#el-viaje') > 0) {
      this.initModal = false;
    }
  }

  send() {
    this.fieldsError = '';
    this.successMsg = false;
    this.errorMsg = false;
    if( this.preguntaTxt != '' && this.myRecaptcha ) {
      this.loading = true;
      this.preguntasService.sendPreguntas(this.preguntaTxt).subscribe( (res:any) => {
        this.loading = false;
        if (res.status == 'success') {
          this.successMsg = true;
          this.preguntaTxt = '';
        } else {
          this.errorMsg = true;
        }
      });
    } else {
      this.fieldsError = '*Escribí tu pregunta en el campo!';
    }
  }

  ngOnInit(): void {
    if (this.pais) {
      this.initModal = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    const mainSlideHeight = this.mainSlide.nativeElement.offsetHeight;

    if (mainSlideHeight * 0.7 < window.pageYOffset) {
      this.smallHeader = true;
    } else {
      this.smallHeader = false;
    }
  }

  openMenu() {
    this.menu = true;
  }

  closeMenu() {
    this.menu = false;
  }

  closeInitModal() {
    this.initModal = false;
  }

  closeAppModal() {
    this.appViaje = false;
    this.appBeneficios = false;
  }

  openAppViaje() {
    this.initModal = true;
    this.appViaje = true;
  }
  openAppBeneficios() {
    this.initModal = true;
    this.appBeneficios = true;
  }
}
