import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-slide',
  templateUrl: './main-slide.component.html',
  styleUrls: ['./main-slide.component.scss'],
})
export class MainSlideComponent implements OnInit {
  @ViewChild('mainSlide') mainSlide: ElementRef;

  menu: boolean = false;
  appViaje: boolean = false;
  appBeneficios: boolean = false;
  
  public smallHeader = false;
  @Input() initModal: boolean = true;
  @Input() smallHeaderAlways = false;
  @Input() pais = false;

  constructor(private router: Router) {
    if (this.router.url.indexOf('#el-viaje') > 0) {
      this.initModal = false;
    }
  }

  ngOnInit(): void {
    if (this.pais) {
      this.initModal = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    const mainSlideHeight = this.mainSlide.nativeElement.offsetHeight;

    if (mainSlideHeight * 0.7 < window.pageYOffset) {
      this.smallHeader = true;
    } else {
      this.smallHeader = false;
    }
  }

  openMenu() {
    this.menu = true;
  }

  closeMenu() {
    this.menu = false;
  }

  closeInitModal() {
    this.initModal = false;
  }

  closeAppModal() {
    this.appViaje = false;
    this.appBeneficios = false;
  }

  openAppViaje() {
    this.initModal = true;
    this.appViaje = true;
  }
  openAppBeneficios() {
    this.initModal = true;
    this.appBeneficios = true;
  }
}
