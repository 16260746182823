<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster">
    <div class="swiper-title" *ngIf="title != ''">
        {{ title }}
    </div>
    <div class="section-text margin-bottom-30" *ngIf="description != ''">
        {{ description }}
    </div>
    <swiper [config]="config">
        <div class="swiper-slide image-text-swiper-slide" *ngFor="let item of listadoItems" wmAnimate="pulse" speed="superFaster" aos once (click)="navigate(item.link)" [class.with-link]="item.link">
            <div class="slide-large-image">
                <img src="{{ item.imageMobile }}" alt="" *ngIf="mobile && item.imageMobile">
                <img src="{{ item.image }}" alt="" *ngIf="mobile && !item.imageMobile">
                <img src="{{ item.image }}" alt="" *ngIf="!mobile">
                <!-- <img src="{{ item.image }}" alt="" *ngIf="!mobile"> -->
            </div>
            <div class="slide-title">
                {{ item.name }}
            </div>
            <div class="slide-subtitle" *ngIf="item.subtitle">
                {{ item.subtitle }}
            </div>
            <div class="slide-btn" *ngIf="item.button">
                CONOCÉ MÁS
            </div>
            <div class="booking" *ngIf="item.url">
                <!-- <a href="{{item.url}}" target="_blank"> -->
                <img src="/assets/imgs/booking_logo.svg" alt="">
                <!-- </a> -->
            </div>
        </div>
    </swiper>
    <!-- <div class="swiper-custom-prev swiper-custom-arrow"></div>
    <div class="swiper-custom-next swiper-custom-arrow"></div> -->
    <div class="clearfix"></div>
    <div class="swiper-custom-pagination"></div>
</div>