<router-outlet></router-outlet>
<div class="floating-footer" *ngIf="showFloatingFooter">
    <div class="ig-button">
        <a href="https://www.instagram.com/hacia.asia.med/" target="_blank">
            <img src="/assets/imgs/ig.svg" alt="">
        </a>
    </div>
    <div class="pdf-button">
        <a href="https://bit.ly/MED2024" target="_blank">
            <img src="/assets/imgs/pdf.svg" alt="">
        </a>
    </div>
</div>