import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { ChartsModule } from '@rinminase/ng-charts';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ComponentsModule } from './components/components.module';
import { AnimateModule } from './animate/animate.module';

import { RecaptchaModule } from 'angular-google-recaptcha';

import {
  SwiperModule,
  SWIPER_CONFIG,
  SwiperConfigInterface,
} from 'ngx-swiper-wrapper';
import { PaisComponent } from './pages/pais/pais.component';
import { RouterModule } from '@angular/router';

import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { PreguntasComponent } from './pages/preguntas/preguntas.component';
import { HttpClientModule } from '@angular/common/http';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  
  direction: 'horizontal',
  slidesPerView: 1.1,
  centeredSlides: true,
  spaceBetween: 10,
  slidesOffsetAfter: 100,
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4.1,
      spaceBetween: 20,
      centeredSlides: false,
    },
  },
  navigation: {
    nextEl: '.swiper-custom-next',
    prevEl: '.swiper-custom-prev',
  },
  pagination: true,
};

@NgModule({
  declarations: [AppComponent, HomeComponent, PaisComponent, PreguntasComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ComponentsModule,
    SwiperModule,
    BrowserAnimationsModule,
    AnimateModule,
    ScrollingModule,
    ChartsModule,
    YouTubePlayerModule,
    RouterModule,
    NgxGoogleAnalyticsModule.forRoot('UA-54212002-3'),
    NgxGoogleAnalyticsRouterModule,
    HttpClientModule,
    RecaptchaModule.forRoot({
      //dev 
      // siteKey: '6LfSD80ZAAAAABfPA4pOKs6BZ-Myngmxkyg1pXja',
      //prod 
      siteKey: '6LeNBhsbAAAAADvykGyYw4tK5ATfDpIYDakix82k',
    })
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
