import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PaisComponent } from './pages/pais/pais.component';
import { PreguntasComponent } from './pages/preguntas/preguntas.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'preguntas',
    component: PreguntasComponent,
  },
  {
    path: 'pais/:country',
    component: PaisComponent,
    // data: {country: 'india'}
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      // anchorScrolling: 'enabled',
      useHash: false,
      anchorScrolling: 'enabled',
      // scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
