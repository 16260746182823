import { Component, OnInit, Input } from '@angular/core';
import { AccordionItem } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() data: AccordionItem[];
  
  constructor() {}

  ngOnInit(): void {}

  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle('active');
    
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
