import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-swiper-image-text-small',
  templateUrl: './swiper-image-text-small.component.html',
  styleUrls: ['./swiper-image-text-small.component.scss'],
})
export class SwiperImageTextSmallComponent implements OnInit {
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: '.swiper-custom-next',
      prevEl: '.swiper-custom-prev',
    },
    pagination: true,
  };
  @Input()
  listadoItems: any[] = [];

  @Input() title = '';
  @Input() mobile = false;
  
  constructor() {}

  ngOnInit(): void {}
}
