<app-standard-slide [initModal]="false"></app-standard-slide>
<div id="first-section" class="main-content top-rounded">
    <div class="section-wrapper">
        <div class="content-section">
            <div class="section-text main-text-page">
                <p>
                    Por un tema razonable de tiempos de la asamblea, y por querer respetarlo, tuvimos que elegir temas para hablar y quedaron algunos por fuera lógicamente... Les dejemos el link al <a href="https://instagram.com/hacia.asia.med" target="_blank">Instagram del viaje 2024 (@hacia.asia.med)</a>                    donde estamos subiendo la infomación que queremos transmitirles. Utilicen este espacio para realizar sus preguntas y leer las de otros compañeros
                </p>
            </div>
        </div>
    </div>
</div>

<div id="third-section" class="main-content top-rounded">
    <div class="section-wrapper">
        <div id="preguntas" class="content-section">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                Sus preguntas
            </div>
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                Preguntas generales...
            </div>
            <div class="preguntas-accordion-wrapper accordion-wrapper">
                <app-accordion [data]="preguntasGenerales"></app-accordion>
            </div>
            <div class="section-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                Sobre la rifa...
            </div>
            <div class="preguntas-accordion-wrapper accordion-wrapper">
                <app-accordion [data]="preguntasRifas"></app-accordion>
            </div>
        </div>
    </div>
</div>
<app-contact></app-contact>