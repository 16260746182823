<app-main-slide></app-main-slide>
<div class="main-container">
    <div id="first-section" class="main-content top-rounded">
        <div class="section-wrapper">
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    A la generación 2018
                </div>
                <div class="section-text">
                    <p>
                        En años de incertezas, de reordenamiento y de desafíos personales internos, nos encontramos planeando la coronación de años de estudio y carrera ni más ni menos con un viaje al otro lado del mundo. Ambiguo sentir... sin dudas. Sin dudas es un lindo combustible para entretenerse, soñar un poco mientras lo imaginamos, y recibir lindos estímulos en momentos donde no abundan.El 2024 no será un año más. Será uno de los años que quedarán en la memoria para siempre... fascinante e introspectivo.<br>Gracias de antemano por el tiempo y la oportunidad de presentarnos y ojalá podamos seguir viajando juntos.
                    </p>
                </div>
            </div>
            <div class="content-section margin-top-30">
                <div class="dark">
                    <div class="swiper-title">
                        Presentamos nuestros 4 pilares
                    </div>
                    <div class="section-text">
                        Nuestra propuesta se basa en 4 pilares fundamentales que consideramos necesarios para llevar a cabo el viaje.
                    </div>
                    <div class="pilares-container">
                        <ng-container *ngFor="let item of pilaresItems; let i = index;">
                            <div class="pilar-item" [class.first]="i==0" [class.last]="i==(pilaresItems.length-1)">
                                <a [href]="item.link">
                                    <div class="pilar-img">
                                        <img src="{{ item.image }}" alt="">
                                    </div>
                                    <div class="pilar-title" *ngIf="item.title != ''">
                                        {{ item.title }}
                                    </div>
                                    <div class="pilar-description " [innerHTML]="item.description"></div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                    <div class="clearfix "></div>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    1 - Seguimos armando el viaje juntos
                </div>
                <div class="section-text">
                    <p>
                        El viaje que conocen de Asia es una construcción que hemos ido haciendo año a año junto con las generaciones que viajaron en 2016, 2018, 2019, 2020, 2021 y 2022, así como también nuevas ideas y propuestas de la generación que viajará en 2023.<br>
                        Y a eso l@s invitamos… a presentarles el viaje que se ha ido construyendo y a abrir el espacio a propuestas que surjan de la generación si se quisiera.
                    </p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    2 - Formas de recaudar (empezá desde ahora)
                </div>
                <div class="section-text">
                    <p>
                        Encontramos las formas más eficientes y transparentes para recaudar dinero.<br>
    Una rifa seria y con premios que deslumbran a los compradores. <br>
    Un viaje al Mundial de Qatar que utilizaremos para empezar a recaudar dinero desde ahora.<br>
    Un bar a disposición para que ustedes y sus conocidos recauden dinero a su cuenta.<br>
    Comisiones buenas por recomendarnos en nuestros viajes.<br>
    Organización de fiestas para recaudar.<br>
                    </p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    3 - Descubrí la medicina del mundo
                </div>
                <div class="section-text">
                    <p>
                        Una experiencia completa. Deja contentos a todos, a la Facultad, a los compradores de rifas y sobre todo a ustedes. Tener una experiencia e inducción en Medicina no tradicional en los países de donde surge, es una experiencia que suma a todo el combo de experiencias que viviremos. Sin compromiso ni obligación, para aquellos que lo deseen.
                    </p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    4 - Respaldo y seguridad de dTyT
                </div>
                <div class="section-text">
                    <p>
                        Son muchos años viajando a Asia con ustedes. Hay un sinfín de imponderables que surgen en los viajes que nos han ido enseñando y de esa forma prepararnos para cualquier situación que surja... enfermedades durante el viaje en lugares inesperados, lesiones, cancelaciones de vuelos por adversidades climáticas, y la mejor de todas... la pandemia, que nos encontró en Asia junto con 400 personas al mismo tiempo y logramos la coordinación de todas las aristas para que en 5 días estar todos en Uruguay.
                    </p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Yendo
                </div>
                <div class="section-text">
                    <p>
                        El viaje está empezando, en este preciso momento, cuando lo imaginamos, cuando lo soñamos, cuando veo con quiénes lo voy a compartir… de repente me encuentro armando las cosas para subirme al avión, y más rápido de lo que pensaba, ya estamos en el aeropuerto.
                        Ahora aterrizados, llegamos! Empezamos a recorrer la ruta que hasta hace unos días nos acompañaba mientras dormíamos… <br>La idea es que el viaje se deslice, que sea fluido, que no sea una excursión. Encontramos
                        y aprendimos todas las instancias del viaje donde aprovechamos las ventajas de viajar en grupo, disfrutarlas y que fluyan. A su vez, algo importante y fundamental del viaje, es tener incontables momentos con mi grupo de amigas y amigos
                        y hasta incluso sola o solo si se quiere, con la confianza y tranquilidad de estar contenidos y protegidos, mientras organizamos y preparamos los próximos movimientos y actividades del grupo.
                    </p>
                </div>
            </div>
            <div class="content-section" wmAnimate="fadeIn" speed="faster" aos once (click)="showPlayer()">
                <div class="video-img">
                    <img src="/assets/imgs/img-video.jpg" alt="">
                </div>
                <div class="video-img-mobile">
                    <img src="/assets/imgs/img-video-mobile.jpg" alt="">
                </div>
                <div class="video-arrow">
                    <img src="/assets/imgs/video-arrow.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="main-content top-rounded gray-section main-content--small-top">
        <div class="section-wrapper">
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Pero antes tenemos sorpresas...
                </div>
                <div class="two-columns margin-top-30">
                    <div class="image-text-item" wmAnimate="pulse" speed="superFaster" aos once>
                        <div class="image-text-item__img">
                            <!-- <img src="{{ item.imageMobile }}" alt="" *ngIf="mobile && item.imageMobile"> -->
                            <!-- <img src="{{ item.image }}" alt="" *ngIf="mobile && !item.imageMobile"> -->
                            <img src="/assets/imgs/Promo_Mundial.jpg" alt="">
                            <!-- <img src="{{ item.image }}" alt="" *ngIf="!mobile"> -->
                        </div>
                        <div class="image-text-item__title">
                            Uno de la generación se va al mundial
                        </div>
                    </div>
                    <div class="image-text-item" wmAnimate="pulse" speed="superFaster" aos once>
                        <div class="image-text-item__img">
                            <!-- <img src="{{ item.imageMobile }}" alt="" *ngIf="mobile && item.imageMobile"> -->
                            <!-- <img src="{{ item.image }}" alt="" *ngIf="mobile && !item.imageMobile"> -->
                            <img src="/assets/imgs/Promo_1Gratis.jpg" alt="">
                            <!-- <img src="{{ item.image }}" alt="" *ngIf="!mobile"> -->
                        </div>
                        <div class="image-text-item__title">
                            1 de cada 50 va gratis
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-content top-rounded white-section">
        <div class="section-wrapper">
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Sobre Nosotros
                </div>
                <div class="section-text">
                    <p>
                        18 personas apasionadas por los viajes, pero sobre todo por estos viajes. <br>
                        Desde el comienzo de dTyT nos centramos y enfocamos en destinos a los cuales no se iba habitualmente, destinos “raros” que hoy parecen más comunes. De hecho, el viaje de FMED a Asia surge por esto mismo en 2014 para viajar en 2016…<br>
                        Viajamos a Asia, a África, a Medio Oriente hace 10 años como destinos exclusivos y eso nos da la posibilidad de mostrar lo distinto, de tener esa experiencia suficiente para dar un plus.<br>
                        Nuestros amigos en cada destino nos hacen vivir una experiencia distinta, conocemos cada callecita, cada ber, cada esquina… <br>
                        Somos uno mas del viaje, aportando la experiencia y estando ahí en todo momento…
                    </p>
                </div>
                <div class="banner">
                    <img src="/assets/imgs/sobre_nosotros.jpg" alt="">
                </div>
            </div>
            <div class="content-section">
                <div id="exp-fmed" class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Nuestra experiencia con FMED
                </div>
                <div class="section-text">
                    En marzo de 2016 estudiantes de la facultad de Medicina decidieron cambiar el rumbo y el estilo de su viaje después de años viajando por el Caribe y Europa.<br>
                    130 personas eligieron ir a Asia; era todo nuevo. Los destinos, la forma, la organización. Y nos fuimos nomás… la experiencia fue realmente alucinante. Fue el único año que viajamos 1 mes, Después de 2016 el viaje empezó a ser de 2 meses y ya no con 130 personas sino con 270 personas cada año.<br>
                    Con las generaciones de 2018, 2019, 2020, 2021, 2022 (cuyos últimos 2 viajes se postergaron por la pandemia) y 2023 (que viajan el próximo Marzo) también tuvimos el placer de compartir todo el proceso de viaje. Teniendo excelentes experiencias y cercanía con todas las personas, permitiéndonos así disfrutar del viaje y recibir las opiniones y sugerencias de cada persona para ir adaptando el viaje cada vez más.<br>
                    Es la opinión de más de 1200 personas la que llevamos, y créannos que fueron, y son, fundamentales para satisfacer de igual forma a 270 personas con distintos gustos, personalidades y emociones.<br>
                </div>
                <div class="charts-swiper-container">
                    <app-swiper-chart title="Encuestas de años anteriores" [listadoItems]="chartItems" theme="dark"></app-swiper-chart>
                </div>
                <div class="mas-encuestas">
                    <a href="https://bit.ly/dTyT_EncMed23" target="_blank">
                        <div class="mas-encuestas-btn">
                            Ver más encuestas
                        </div>
                    </a>
                </div>
                <div class="group-images-swiper" wmAnimate="fadeIn" speed="faster" aos once>
                    <app-swiper-large-image [listadoItems]="groupsImages" [mobile]="mobile"></app-swiper-large-image>
                </div>
            </div>
    </div>
    <div id="pilar1" class="main-content top-rounded gray-section">
        <div class="section-wrapper">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                <div class="orange-title">
                    PILAR 1
                </div>
                Decidí sobre tus destinos - El Viaje
            </div>
            <div class="content-section">
                <!-- <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Itinerario
                </div> -->
                <div class="section-text">
                    <p>El viaje que conocen de Asia es una construcción que hemos ido haciendo año a año junto con las generaciones que viajaron en 2016, 2018, 2019, 2020, 2021 y 2022, así como también nuevas ideas y propuestas de la generación que viajará en 2023.</p>
    
                    <p>Y a eso l@s invitamos… a presentarles el viaje que se ha ido construyendo y a abrir el espacio a propuestas que surjan de la generación si se quisiera.</p>
    
                    <p>Para ello se abre una <strong>“comisión destinos”</strong> donde cualquiera que quisiera participar pueda ser el nexo entre la generación y nosotros, para ir planteando y co-creando el viaje.</p>
    
                    <p>Y en caso de que no quieran realizar cambios, saben que cuentan con un viaje minuciosamente pensado y con garantía de que fue construido y probado por 1400 médic@s con los cuales mantenemos un lindo vínculo post viaje.</p>
    
    
                    <p>Mas allá de lo explicado mas arriba sobre las ganas y el compromiso de organizar el viaje juntos y que no sea una imposición, les dejamos los itinerarios que hemos hecho años anteriores para que tengan como referencia y que podrá ser (si así lo deciden) el suyo.</p>
                    <p>Un día a día pensado y planeado, contemplando la cantidad de días necesarios en cada lugar, teniendo en cuenta el orden de la visita a los distintos países para que se disfruten como se debe (Ej: ir a India al principio es fundamental.) Como verán en el siguiente cuadro, encontraremos 2 bloques de viaje; El “Núcleo” y “Extensiones”.</p>
                    <p>El Núcleo es la parte del viaje clave y central, donde reúne lo esencial de una visita por Asia. Playa, cultura, historia, noche, lindos paseos, etc.</p>
                    <p>Es el destino en el que estaría toda la generación junta ya que luego las extensiones serían opcionales.</p>
                    <p>Para la elección de las extensiones los invitamos a reunirnos las veces que sean necesarias en la oficina y a su vez en la “Parte 3” del PDF tendrán información acerca de todos los destinos para que tengan referencias.</p>
                </div>
                <div id="" class="countries-swiper-container swiper-itinerario margin-top-30">
                    <app-swiper-image-text title="" [listadoItems]="paises" [mobile]="mobile" theme="dark"></app-swiper-image-text>
                </div>
                <div class="section-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                    Precios
                </div>
                <div class="section-text">
                    <p>
                        Por cada extensión que se elija <strong>se descontarán USD 100 del precio del núcleo.</strong>
                    </p>
                </div>
                <div class="tramos-container">
                    <app-tramos [tramos]="tramosAsia"></app-tramos>
                </div>
            </div>
            <div class="separator"></div>
            <div class="content-section">
                <div class="section-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                    Extensiones a pedido de otras generaciones
                </div>
                <div class="section-text">
                    <p>
                        Este año se proponen otras 2 extensiones: Europa y África. Hay para todos los gustos, en cuanto a días, presupuestos, destinos, etc.<br> Dependiendo de la cantidad de gente interesada, se podrá realizar una o ambas.<br> Los invitamos
                        también a juntarnos para charlar sobre estas 2 nuevas extensiones.
                    </p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-sub-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                    Extensión Europa
                </div>
                <div class="section-text">
                    <p>
                        Planteo nuevo de la generación, con detalles a tener en cuenta y a conversar en el zoom.
                    </p>
                </div>
                <div id="" class="countries-swiper-container margin-top-30 swiper-itinerario">
                    <app-swiper-image-text title="" [listadoItems]="paisesEuropa" [mobile]="mobile" theme="dark"></app-swiper-image-text>
                </div>
                <div class="tramos-container">
                    <app-tramos [tramos]="tramosEuropa"></app-tramos>
                </div>
            </div>
            <div class="separator"></div>
            <div class="content-section">
                <div class="section-sub-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                    Extensión África
                </div>
                <div class="section-text">
                    <p>
                        Sugerencia de generaciones anteriores para innovar respecto a las generaciones previas.
                    </p>
                </div>
                <div id="" class="countries-swiper-container margin-top-30 swiper-itinerario">
                    <app-swiper-image-text title="" [listadoItems]="paisesAfrica" [mobile]="mobile" theme="dark"></app-swiper-image-text>
                </div>
                <div class="tramos-container">
                    <app-tramos [tramos]="tramosAfrica"></app-tramos>
                </div>
            </div>
            <div class="separator"></div>
            <div class="content-section margin-top-30">
                <app-swiper-small title="Qué incluye?" description="En los precios antedichos decidimos incluir actividades y todas las cosas necesarias que sí o sí se terminan gastando en el viaje con el fin de que puedan calcular bien cuánto llevar para gastar y que no haya sorpresas."
                    [listadoItems]="incluyeGeneralItems" theme="light"></app-swiper-small>
            </div>
        </div>
    </div>
    <div id="pilar2" class="main-content top-rounded white-section">
        <div class="section-wrapper">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                <div class="orange-title">
                    PILAR 2
                </div>
                Formas de recaudar
            </div>
            <div class="content-section">
                <app-swiper-small title="" description="Encontramos las formas más eficientes y transparentes para recaudar dinero"
                    [listadoItems]="recaudarItems" theme="full-image"></app-swiper-small>
            </div>
            <div class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Rifas
                </div>
                <div class="section-text">
                    <p>Para hacer este hermoso y apasionante viaje sabemos lo necesario que es financiarlo a través de rifas. Es la herramienta fundamental para poder obtener el dinero para realizar el viaje. Si bien lo protagónico en el proceso del viaje son los destinos, los paseos, el grupo y más detalles, sin la rifa nada de esto sería viable. Es por esto que nos esmeramos en hacer la mejor rifa, con los mejores premios, con altas probabilidades de ganar y a su vez con beneficios directos para los compradores por solo el hecho de comprar la rifa para que la venta se haga más sencilla y se obtengan las ganancias esperadas.</p>
                    <p>La rifa es una herramienta que “de Toque y Toque” organiza hace años y que ha hecho posible que todas las generaciones anteriores puedan realizar este hermoso viaje.</p>
                    <p>Una rifa probada, seria y totalmente transparente con la habilitación de la Dirección Nacional de Loterías y Quinielas.</p>
                </div>
                <div class="banner banner-desktop" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/rifa-banner.jpg" alt="">
                </div>
                <div class="banner banner-mobile" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/rifa-banner-mobile.jpg" alt="">
                </div>
            </div>
            <div class="content-section">
                <div class="premios-swiper-container">
                    <app-swiper-image-text title="Premios" [listadoItems]="premios" description="Basándonos en todos los años anteriores, con una emisión de 20.000 rifas año a año."></app-swiper-image-text>
                </div>
            </div>
            <div class="content-section">
                <div class="two-columns margin-top-70">
                    <div class="column-item column-item-img desktop">
                        <img src="/assets/imgs/recaudar/recaudar-large_2.jpg">    
                    </div>
                    <div class="column-item column-item-text">
                        <div class="column-item-title section-title">
                            Sorteo al Mundial Qatar 2022
                        </div>
                        <div class="column-item-text section-text">
                            <p>Un sorteo exclusivo para los estudiantes que viajen con dTyT.</p>
                            <p>Son bonos de 50 usd donde se recaudan USD 40. Este movimiento es un éxito y hay que aprovecharlo.</p>
                            <p>Se sortea un viaje al Mundial para seguir a Uruguay con todo pago en nuestro grupo de viaje de “de Toque y Toque”.</p>
                            <p>Cada 1000 personas que participen se sorteará 1 viaje!</p>
                            <p>Valor del bono: 50 USD.</p>
                            <p>Se pueden vender todos los bonos que quieran!</p>
                            <p>Es una forma rápida y sumamente atractiva (a quien no le gustaría ganarse un viaje al mundial) para recaudar dinero.</p>
                            <p>Y esto nada tiene que ver con la rifa, que será recién el año que viene.</p>
                        </div>
                    </div>
                    <div class="column-item column-item-img mobile">
                        <img src="/assets/imgs/recaudar/recaudar_2.jpg">    
                    </div>
                </div>
                <div class="two-columns">
                    <div class="column-item column-item-text">
                        <div class="column-item-title section-title">
                            Recomendanos
                        </div>
                        <div class="column-item-text section-text">
                            <p>Recauda USD 200 por pasajero que se sume a nuestros viajes de 1 mes. en el año 2023. (si el viaje es más corto, se recauda igual, proporcionalmente a la duración del viaje)</p>
                            <p>Si una persona viaja con nosotros y menciona tu nombre, automáticamente se te acredita USD 200 en tu estado de cuenta para realizar el viaje de generación.</p>
                            <p>Los destinos son: India / Nepal / Tailandia / Camboya / Vientam / Indonesia / Filipinas / Egipto / Jordania / Israel / Turquía / Sudáfrica / Zimbabwe / Zambia / Tanzania / Perú</p>
                        </div>
                    </div>
                    <div class="column-item column-item-img desktop">
                        <img src="/assets/imgs/recaudar/recaudar-large_3.jpg">    
                    </div>
                    <div class="column-item column-item-img mobile">
                        <img src="/assets/imgs/recaudar/recaudar_3.jpg">    
                    </div>
                </div>
                <div class="two-columns">
                    <div class="column-item column-item-img desktop">
                        <img src="/assets/imgs/recaudar/recaudar-large_4.jpg">    
                    </div>
                    <div class="column-item column-item-text">
                        <div class="column-item-title section-title">
                            1 huella = 1 dólar
                        </div>
                        <div class="column-item-text section-text">
                            <p>Por cada consumición que hagas en el Bar “de Toque y Toque” recaudas el 10% en huellas para tu viaje. </p>
                            <p>Lo mejor… podes decirle a tu gente conocida que venga por el bar y pida sus huellas para entregártelas para que las uses para tu viaje.</p>
                            <p>Aplica para todo: para venir a cenar y tomar algo, así como también para las noches de Funk de viernes y noches de Cachengue los sábados. </p>
                            <p class="comment-text">*No válido para noviembre y diciembre</p>
                        </div>
                    </div>
                    <div class="column-item column-item-img mobile">
                        <img src="/assets/imgs/recaudar/recaudar_4.jpg">    
                    </div>
                </div>
                <div class="two-columns">
                    <div class="column-item column-item-text">
                        <div class="column-item-title section-title">
                            Fiestas en Lotus
                        </div>
                        <div class="column-item-text section-text">
                            <p>Es la menos utilizada porque requiere de más organización por parte de la generación, pero se hacen 2 o 3 por año. </p>
                            <p>Tenemos Lotus a disposición para organizar eventos de recaudación donde el precio de la entrada es para recaudar dinero para el viaje.</p>
                        </div>
                    </div>
                    <div class="column-item column-item-img desktop">
                        <img src="/assets/imgs/recaudar/recaudar-large_5.jpg">    
                    </div>
                    <div class="column-item column-item-img mobile">
                        <img src="/assets/imgs/recaudar/recaudar_5.jpg">    
                    </div>
                </div>
                <div class="two-columns">
                    <div class="column-item column-item-img desktop">
                        <img src="/assets/imgs/recaudar/recaudar-large_6.jpg">    
                    </div>
                    <div class="column-item column-item-text">
                        <div class="column-item-title section-title">
                            Espacio Bar dTyT
                        </div>
                        <div class="column-item-text section-text">
                            <p>La casona, “dTyT bar”, el lugar de encuentro de los viajeros está 100% a disposición para organizar lo que se guste.</p>
                            <p>Ya sea para juntarse a charlar del viaje o para recaudar dinero realizando fiestas, after office, cenas, jornadas o lo que se les ocurra!</p>
                            <p>De hecho, hasta se llegó a usar para dar clases de FMED jajaj.</p>
                        </div>
                    </div>
                    <div class="column-item column-item-img mobile">
                        <img src="/assets/imgs/recaudar/recaudar_6.jpg">    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="pilar3" class="main-content top-rounded gray-section">
        <div class="section-wrapper">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                <div class="orange-title">
                    PILAR 3
                </div>
                “Descubriendo la medicina del mundo”
            </div>
            <div class="content-section">
                <!-- <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Itinerario
                </div> -->
                <div class="section-text">
                    <p>A partir de la generación que viajó en 2019 logramos oficializar junto con los estudiantes de la Facultad y el consejo de la FMed que el viaje quede como “viaje de la Facultad de Medicina”. </p>
                    <p>Esto fue un gran paso ya que la Facultad es parte del viaje y eso implica la posibilidad de negociar fechas de exámenes, hacer pequeños cursos sobre Medicina en Asia y el apoyo y soporte para la tramitación de las rifas. Fue un gran paso que se dio y que se mantiene hasta el momento.</p>
                    <p>La experiencia de los cursos fue realmente buena. Se visitaron 2 hospitales (uno en India y otro en Vietnam), pudiendo ver distintas realidades en lo que tiene que ver con la medicina de aquellos lugares.</p>
                    <p>La visita a los hospitales es un plus al viaje y es totalmente opcional y está incluida en el precio.</p>
                    <p>En el 2020 además de poder visitar algún hospital, en India realizamos una jornada de medicina ayurvédica conociendo y experimentando algo distinto y que suma a la experiencia de la medicina no tradicional.</p>
                    <p>La idea al igual que en el viaje del 2019 y 2020, es, en 2 o máximo 3 ciudades puntuales y sin perder el objetivo de celebración y festividad que tiene el mismo, se coordine y organice una visita a hospitales para ver realidades y problemáticas distintas a las que vivimos en Uruguay. Para ello contamos con contactos y amistades que nos presentaron médicos e instituciones que nos recibirán y contarán sus historias.</p>
                    <p>Esperemos les guste... esto es un gran paso para la Facultad de Medicina y todas las generaciones que vienen. Nosotros venimos trabajando para que esto suceda desde hace 6 años y recién hace 4 años pudimos hacer que la Facultad se involucre y apoye y de esta forma poder contar con un viaje oficial de la Facultad de Medicina, siendo el tercero luego de Ciencias Económicas y Arquitectura. </p>
                </div>
                <div class="group-images-swiper">
                    <app-swiper-large-image [listadoItems]="academicoImages" [mobile]="mobile"></app-swiper-large-image>
                </div>
            </div>
        </div>
    </div>
    <div id="pilar4" class="main-content top-rounded white-section">
        <div class="section-wrapper">
            <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
                <div class="orange-title">
                    PILAR 4
                </div>
                “Seguridad y Respaldo”
            </div>
            <div class="content-section">
                <div class="section-text">
                    <p>Imagínense la cantidad de cosas que pasan con 300 personas viajando por 2 meses en Asia... </p>
                    <p><strong>Estadísticamente a 2 o 3 personas se les pierde el pasaporte...</strong></p>
                    <p><strong>Estadísticamente 1 o 2 vuelos internos se cancelan...</strong></p>
                    <p><strong>Estadísticamente a 4 o 5 les pasa algo a nivel de lesiones...</strong></p>
                    <p><strong>Estadísticamente en el mundo pasa algo que repercute en todos los países... </strong></p>
    
                    <p>Entonces... primer punto... para todas esas cosas que pueden pasar, intenten y procuren no ser ustedes a los que les pase.</p>
    
                    <p>Pero como la estadística no falla, para todas las cosas que aparecen y suceden, hay que buscarles soluciones urgentes.</p>
                    <p>Para todas estas cosas contamos con la experiencia de haberlas vivido... todas.</p>
                    <p>Seremos 11 personas de "dTyT" ahí mismo que ya atravesamos por estas situaciones.</p>
                    <p>Conocemos todas las embajadas y consulados de Uruguay, contacto directo por whatsapp.</p>
                    <p>Tenemos flexibilidad de reacomodar cualquier plan en caso de que se tenga que cambiar el rumbo del viaje.</p>
    
                    <p>Además... hemos estado viajando en pandemia y ya se viaja de una forma distinta. No es más lo mismo que era antes... ojo, capaz en 2024 ya vuelve todo a la normalidad... pero mientras tanto, hay que prepararse para todo.</p>
    
                    <p>Eso... escribimos estas breves palabras para intentar transmitir, aunque sea mínimo, el trabajo verdadero que hay detrás... no es solo ir y que todo salga según lo planeado... es el compromiso que uno asume de que cada una de las personas que está viajando esté respaldada y no se sienta sola ni en las peores situaciones a pesar de ser ajenas a la organización.</p>
                </div>
            </div>
            <div class="content-section">
                <div class="section-title section-title-with-icon" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/check-icon.svg" alt=""> COVID-19: Viaje Seguro
                </div>
                <div class="section-text">
                    <p>Es nuestra política de devoluciones por COVID. En caso de que un viaje se tenga que cancelar o aplazar, la/el viajer@ tendrá siempre la opción de poder realizar otro viaje con el dinero recaudado, incluso la posibilidad de cambio de viaje
                        se podrá realizar también por temas personales justificados.</p>
                </div>
                <div class="section-title section-title-with-icon" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/check-icon.svg" alt=""> Garantía de viaje
                </div>
                <div class="section-text">
                    <p>Es nuestra política para asegurar al usuario que el viaje se va a desarrollar con normalidad (época pre COVID), de lo contrario evitaremos realizar el viaje o se comunicará previamente alguna condición que haya en el país a visitar para
                        recibir el consentimiento de quienes vayan a viajar.<br> Cualquier decisión será tomada con el consentimiento de la generación.</p>
                </div>
                <div class="section-title section-title-with-icon" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/check-icon.svg" alt=""> Seguro de viaje total
                </div>
                <div class="section-text">
                    <p>El seguro de viaje incluido asiste a los viajer@s en cualquier situación o momento. Incluso en casos de COVID.</p>
                    <p>Junto a Assist Card venimos viajando desde hace muchos años y hemos transitado innumerables situaciones que inevitablemente se dan a lo largo de los viajes y la respuesta del seguro es vital y es probada... </p>
                    <p>100.000 usd de cobertura y algo no menor... un seguro ANUAL!</p>
                    <p>Viajar con un seguro probado en situaciones como las que se viven en viajes de 200 personas es una tranquilidad extra. </p>
                </div>
            </div>
        </div>
    </div>
    <div id="second-section" class="main-content top-rounded">
        <div id="el-viaje" class="section-wrapper">
            <div id="rifas" class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    App de gestión de rifas
                </div>
                <div class="section-text">
                    <p>
                        Con la App de Gestión de Rifas, podrás tener un control total de tus ventas, tus pagos, tus rifas, etc.<br> Y lo mejor: Podrás vender tus rifas sin necesidad de tener que ir a la casa de los compradores mediante el sistema seguro de
                        pago online!<br> Acá van alguno de los beneficios!
                    </p>
                </div>
                <div class="banner banner-desktop" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/AppRifas-Venta.jpg" alt="">
                </div>
                <div class="banner banner-mobile" wmAnimate="fadeIn" speed="faster" aos once>
                    <!-- <img src="/assets/imgs/AppRifas-Venta-mobile.jpg" alt=""> -->
                </div>
                <div class="rifas-accordion-wrapper accordion-wrapper">
                    <app-accordion [data]="rifasAccordion"></app-accordion>
                </div>
                <div class="section-text">
                    &nbsp;*Si tenés más dudas sobre la rifa, podrás encontrar más información en nuestra propuesta en PDF
                </div>
            </div>
        </div>
    </div>
    <div id="third-section" class="main-content top-rounded">
        <div class="section-wrapper">
            <div class="content-section">
                <div id="alianzas-convenios" class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Alianzas y Convenios
                </div>
                <div class="section-title orange-title margin-top-30" wmAnimate="fadeIn" speed="faster" aos once>
                    Volvé a viajar con "de Toque y Toque"
                </div>
                <div class="section-text">
                    <p>
                        Con la participación del viaje de Medicina 2024, accedés a un vale de USD 1000 en nuestros otros viajes por Asia o por África que tienen 1 mes de duración para usar cuando quieras, sin vencimiento. Si quisieras viajar menos tiempo, el vale es proporcional a la cantidad de días que viajes!<br>
                        Viajar es una pasión que no termina en 2024 y queremos acompañarte.
                    </p>
                </div>
                <div class="banner banner-desktop" wmAnimate="fadeIn" speed="faster" aos once>
                    <img src="/assets/imgs/volve_viajar.jpg" alt="">
                </div>
                <div class="section-text">
                    <p>
                        A continuación les presentamos los convenios que tenemos con diferentes proveedores para agregarle valor al viaje. Son proveedores que sí o sí van a ser utilizados por distintas personas dependiendo de sus gustos y necesidades y con los cuales hemos generado
                        una relación de trabajo muy práctica y efectiva.
                    </p>
                </div>
                <div class="countries-swiper-container desktop">
                    <div class="beneficio-item" *ngFor="let beneficio of beneficiosItems">
                        <div class="beneficio-left">
                            <div class="beneficio-image">
                                <img [src]="beneficio.image" alt="">
                            </div>
                        </div>
                        <div class="beneficio-right">
                            <div class="beneficio-title">
                                {{ beneficio.title }}
                            </div>
                            <div class="beneficio-description">
                                {{ beneficio.description }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="countries-swiper-container mobile">
                    <app-swiper-small title="" [listadoItems]="beneficiosItems" theme="light"></app-swiper-small>
                </div>
            </div>
            <div id="preguntas" class="content-section">
                <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                    Preguntas frecuentes
                </div>
                <div class="preguntas-accordion-wrapper accordion-wrapper">
                    <app-accordion [data]="preguntasAccordion"></app-accordion>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-player-wrapper" *ngIf="showVideo">
    <div class="close-video" (click)="hidePlayer()">
        <img src="/assets/imgs/go-back.svg" alt="">
    </div>
    <div class="modal-player">
        <video controls="controls" tabindex="0" class="video-player-container">
            <source src="/assets/imgs/video-medicina.mp4"/>
        </video>
        <!-- <app-youtube videoId="DGSGhQBphf4"></app-youtube> -->
    </div>
</div>
<app-contact></app-contact>