import { Component, OnInit, Input } from '@angular/core';
// import { StandardItem } from 'src/app/interfaces/interfaces';
import { ChartItem } from '../../../interfaces/interfaces';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-swiper-chart',
  templateUrl: './swiper-chart.component.html',
  styleUrls: ['./swiper-chart.component.scss'],
})
export class SwiperChartComponent implements OnInit {
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2.1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4.1,
        spaceBetween: 20,
        centeredSlides: false,
      },
    },
    navigation: {
      nextEl: '.swiper-chart-next',
      prevEl: '.swiper-chart-prev',
    },
    pagination: true,
  };

  @Input()
  title = '';
  @Input()
  theme = 'light';
  @Input()
  listadoItems: ChartItem[] = [];
  constructor() {}

  ngOnInit(): void {}
}
