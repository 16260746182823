<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster">
    <swiper>
        <div class="swiper-slide" *ngFor="let pais of listadoPaises; let i = index" #animateComponent [class.small-slide]="firstClick" wmAnimate="pulse" speed="superFaster" disabled="true" aos once>
            <div class="slide-image">
                <img *ngIf="!firstClick" src="{{ pais.image }}" alt="">
                <!-- <img *ngIf="firstClick" src="{{ pais.smallImage }}" alt=""> -->
            </div>
            <div class="slide-title">
                {{ pais.name }}
            </div>
            <div class="slide-subtitle" *ngIf="!firstClick">
                {{ pais.dias }}
            </div>
        </div>
    </swiper>
    <!-- <div class="swiper-custom-prev swiper-custom-arrow"></div>
    <div class="swiper-custom-next swiper-custom-arrow"></div> -->
</div>