<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster" [class.dark]="theme == 'dark'" [class.light]="theme == 'light'">
    <div class="swiper-title">
        {{ title }}
    </div>
    <div class="section-text">
        {{ description }}
    </div>
    <ng-container *ngIf="theme == 'full-image'">
        <swiper>
            <ng-container *ngFor="let item of listadoItems;">
                <div class="pilar-item swiper-slide small-swiper-slide">
                    <div class="pilar-img">
                        <img src="{{ item.image }}" alt="">
                    </div>
                    <div class="pilar-title" *ngIf="item.title != ''">
                        {{ item.title }}
                    </div>
                    <div class="pilar-description " [innerHTML]="item.description"></div>
                </div>
            </ng-container>
        </swiper>
    </ng-container>
    <ng-container *ngIf="theme != 'full-image'">
        <swiper>
            <div class="swiper-slide small-swiper-slide" *ngFor="let item of listadoItems" wmAnimate="pulse" speed="superFaster" aos once>
                <div class="slide-image">
                    <img src="{{ item.image }}" alt="">
                </div>
                <div class="small-slide-title" *ngIf="item.title != ''">
                    {{ item.title }}
                </div>
                <div class=" small-slide-description " [innerHTML]="item.description">
                    <!-- {{ item.description }} -->
                </div>
            </div>
        </swiper>
    </ng-container>
    <!-- <div class="swiper-custom-prev swiper-custom-arrow "></div>
    <div class="swiper-custom-next swiper-custom-arrow "></div> -->
    <div class="clearfix "></div>
    <div class="swiper-custom-pagination "></div>
</div>